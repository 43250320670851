$btn-white-space: nowrap;
$primary: #233b6d;
$border-radius: 0.25rem;
$font-family: var(--bs-body-font-family);
$theme-colors: (
  
	'accent': #4d79ff,
	'primary': #233b6d,
	'primary-dark': #1a2e54,
	'primary-light': #486aaf,
	'primary-text': #333333,
	'secondary-text': #bfbfbf,
	'text-on-primary': #ffffff,
	'text-on-accent': #ffffff,
	'danger': #dc3545,
	'info': #17a2b8,
	'success': #28a745,
	'warning': #fce359,
	'dark': #343a40,
	'light': #f8f9fa,
	'blue': #007bff,
	'cyan': #17a2b8,
	'gray': #6c757d,
	'green': #28a745,
	'indigo': #6610f2,
	'orange': #fd7e14,
	'pink': #e83e8c,
	'purple': #6f42c1,
	'red': #dc3545,
	'teal': #20c997,
	'white': #ffffff,
	'yellow': #ffc107,
	'lighten': #ffffffe6,
	'primary-darken': #1a2e54de,
	'lightest': #fbfbfb,
	'primary-lightest': #e4e5ea,
	'grey_fifty': #dedede,
) !default;
