.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.min-w-320px {
	min-width: 320px !important;
}

.max-w-400px {
	max-width: 400px !important;
}

.w-144px {
	width: 144px !important;
}

.w-24px {
	width: 24px !important;
}

.max-w-300px {
	max-width: 300px !important;
}

.w-256px {
	width: 256px !important;
}

.w-192px {
	width: 192px !important;
}

.max-w-1000px {
	max-width: 1000px !important;
}

.w-80 {
	width: 80% !important;
}

.min-w-300px {
	min-width: 300px !important;
}

.w-32px {
	width: 32px !important;
}

.w-128px {
	width: 128px !important;
}

.w-64px {
	width: 64px !important;
}

.w-50 {
	width: 50% !important;
}

.max-w-1200px {
	max-width: 1200px !important;
}

.w-90 {
	width: 90% !important;
}

.max-w-100 {
	max-width: 100% !important;
}

.min-w-250px {
	min-width: 250px !important;
}

