.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.mt-24px {
	margin-top: 24px;
}

.mt-32px {
	margin-top: 32px;
}

.mt-16px {
	margin-top: 16px;
}

.mt-6px {
	margin-top: 6px;
}

.mt-48px {
	margin-top: 48px;
}

.mt-8px {
	margin-top: 8px;
}

.m-8px {
	margin: 8px;
}

.ms-24px {
	margin-left: 24px;
}

.me-24px {
	margin-right: 24px;
}

.ms-16px {
	margin-left: 16px;
}

.m-48px {
	margin: 48px;
}

.m-16px {
	margin: 16px;
}

.mt-64px {
	margin-top: 64px;
}

.mt-128px {
	margin-top: 128px;
}

.m-24px {
	margin: 24px;
}

.mt-20px {
	margin-top: 20px;
}

.mx-8px {
	margin-left: 8px;
	margin-right: 8px;
}

