.f-20px {
  font-size: 20px;
}

.f-28px {
  font-size: 28px;
}

.f-30px {
  font-size: 30px;
}

.f-40px {
  font-size: 40px;
}

.f-small {
  font-size: small;
}

.f-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}


@font-face {
	font-family: renner;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-renner {
font-family: renner;
}

@font-face {
	font-family: hurmegeometricsans3black;
	src: url('../../assets/fonts/HurmeGeometricSans3Black.otf');
}

.ff-hurmegeometricsans3black {
font-family: hurmegeometricsans3black;
}

@font-face {
	font-family: merriweatherblack;
	src: url('../../assets/fonts/Merriweather-Black.ttf');
}

.ff-merriweatherblack {
font-family: merriweatherblack;
}

@font-face {
	font-family: merriweatherblackitalic;
	src: url('../../assets/fonts/Merriweather-BlackItalic.ttf');
}

.ff-merriweatherblackitalic {
font-family: merriweatherblackitalic;
}

@font-face {
	font-family: merriweatherbold;
	src: url('../../assets/fonts/Merriweather-Bold.ttf');
}

.ff-merriweatherbold {
font-family: merriweatherbold;
}

@font-face {
	font-family: merriweatherbolditalic;
	src: url('../../assets/fonts/Merriweather-BoldItalic.ttf');
}

.ff-merriweatherbolditalic {
font-family: merriweatherbolditalic;
}

@font-face {
	font-family: merriweatheritalic;
	src: url('../../assets/fonts/Merriweather-Italic.ttf');
}

.ff-merriweatheritalic {
font-family: merriweatheritalic;
}

@font-face {
	font-family: merriweatherlight;
	src: url('../../assets/fonts/Merriweather-Light.ttf');
}

.ff-merriweatherlight {
font-family: merriweatherlight;
}

@font-face {
	font-family: merriweatherlightitalic;
	src: url('../../assets/fonts/Merriweather-LightItalic.ttf');
}

.ff-merriweatherlightitalic {
font-family: merriweatherlightitalic;
}

@font-face {
	font-family: merriweatherregular;
	src: url('../../assets/fonts/Merriweather-Regular.ttf');
}

.ff-merriweatherregular {
font-family: merriweatherregular;
}

@font-face {
	font-family: regular;
	src: url('../../assets/fonts/Merriweather-Regular.ttf');
}

.ff-regular {
font-family: regular;
}

@font-face {
	font-family: titles;
	src: url('../../assets/fonts/Merriweather-Black.ttf');
}

.ff-titles {
font-family: titles;
}

@font-face {
	font-family: bold;
	src: url('../../assets/fonts/Merriweather-Bold.ttf');
}

.ff-bold {
font-family: bold;
}

@font-face {
	font-family: buttons;
	src: url('../../assets/fonts/Merriweather-Light.ttf');
}

.ff-buttons {
font-family: buttons;
}



// font
