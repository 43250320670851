body {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

img {
  display: block;
}
